<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snack"
      color="primary"
      bottom
    >
      {{ textoSnack }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      touchless
      absolute
      width="100%"
      permanent
    >
      <v-card
        color="rgba(0, 0, 0, .7)"
        light
        max-width="80%"
        width="300px"
      >
        <v-card-text class="text-center">
          <div class="d-flex flex-column align-center py-5">
            <v-img
              :src="imgLogo"
              max-width="200"
            />
          </div>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="usuario"
              :disabled="carga"
              :rules="regla"
              label="Usuario"
              color="white"
              dark
              outlined
              dense
              prepend-inner-icon="mdi-face-recognition"
              onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
            />
            <v-text-field
              v-model="clave"
              :disabled="carga"
              :rules="regla"
              label="Clave"
              color="white"
              dark
              outlined
              dense
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              prepend-inner-icon="mdi-lock-outline"
              onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
              @click:append="show = !show"
            />
          </v-form>

          <v-btn
            id="btnLog"
            block
            color="white"
            class="font-weight-bold"
            @click="loginData()"
          >
            ingresar
          </v-btn>
          <br>
          <div
            class="text-body-2 font-weight-bold white--text"
          >
            OID-TEC    {{ new Date().getFullYear() }} &copy; CSJLA
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Login',
    data: () => ({
      snack: false,
      textoSnack: '',
      usuario: '',
      clave: '',
      carga: false,
      sedeCorteData: '',
      regla: [v => !!v || 'El campo es requerido'],
      imgFondo: '',
      imgLogo: '',
      error: false,
      textoError: '',
      show: false,
    }),
    created () {
      this.$store.commit('SET_VALOR', true)
      this.imgLogo = window.__env.dataCfg.imgLogoN
      if (localStorage.getItem('usuarioAlien')) {
        this.$router.replace('/consulta')
      }
    },
    mounted () {
      this.$store.commit('SET_VALOR', true)
    },
    // method for gettin text input
    methods: {
      descargarManual (url, name) {
        var link = document.createElement('a')
        link.download = name
        link.href = url
        link.setAttribute('target', '_blank')
        link.click()
        link.remove()
      },
      loginData () {
        if (this.$refs.form.validate()) {
          this.carga = true
          const data = {
            usuario: this.usuario,
            contrasena: this.clave,
          }
          axios
            .post(`${window.__env.dataCfg.urlApiAlien}a0/login`, data)
            .then((r) => {
              if (r.data.success === true) {
                r.data.data[0].token = r.data.token
                localStorage.setItem('usuarioAlien', JSON.stringify(r.data.data[0]))
                // localStorage.setItem('tokenBusqueda', r.data.token)
                this.carga = false
                this.$router.replace(
                  { path: 'servidor' },
                  () => {
                    this.$router.go(0)
                  },
                )
              } else {
                this.textoSnack = 'Usuario o contraseña incorrecta'
                this.snack = true
                this.carga = false
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
    },
  }
</script>
<style lang="sass">
.centrado
  position: absolute
  top: 30%
  left: 50%
  transform: translate(-50%, -50%)

.foot
  position: absolute
  top: 65%
  left: 50%
  transform: translate(-50%, -50%)

.v-card
  position: absolute
  top: 43%
  left: 50%
  transform: translate(-50%, -50%)

.v-navigation-drawer
  background-image: url('/assets/b.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
</style>
